@font-face {
  font-family: 'Share Tech';
  font-style: normal;
  font-weight: 400;
  src: local('Share Tech'), local('ShareTech-Regular'), url(/fonts/ShareTech-Regular.woff2) format('woff2'), url(/fonts/ShareTech-Regular.ttf) format('truetype');
}

* {
  background-color: transparent;
  font-family: $base-font-family;
}

body,
.jumbotron {
  background-color: $background-color;
}

body {
  padding-top: 52px;
}

.jumbotron {
  margin: 0;
  padding: 0;
  .post-title {
    font-size: 42px;
    font-weight: normal;
  }
  &>hr {
    background-color: #333;
    height: 1px;
    border: 3px #333 solid;
    width: 300px;
  }
}

.navbar {
  background-color: $background-color;
  border: none;
  /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);*/
  .divider-vertical {
    height: 20px;
    margin: 14px 6px;
    border-right: 1px solid $nav-link-color;
    border-left: 1px solid $nav-link-color;
  }
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 600px;
}

ul.navbar-right {
  display: flex;
}

ul.navbar-right li {
  flex: 1;
  white-space: nowrap;
}

ul.navbar-right li#spacer {
  flex: 0;
}

ul.navbar-nav li a {
  color: $nav-link-color !important;
}

.btn-default {
  border: 0;
  background-color: transparent;
  color: $nav-link-color;
}

a {
  color: $nav-link-color;
  &:visited {
    color: $nav-link-color;
  }
}

.row {
  padding: 75px 0;
  &-centered {
    text-align: center;
    margin: 0;
  }
}

#large-header {
  background: #222 url('/images/logowall.svg') center top;
  background-size: 450px;
  height: 300px;
  margin: 0 0 100px 0;
  padding: 0;
  img {
    position: relative;
    top: 30px;
    width: 300px;
  }
}

.col-centered {
  display: inline-block;
  float: none;
  text-align: left;
  margin-right: -4px;
}

ul.nav>li>a {
  color: $nav-link-color;
}

.post-content img {
  display: block;
  margin: auto;
}

#status_open,
#status_closed {
  display: none;
  border: 0;
}

#status_open a {
  color: #fff !important;
}

#status_closed a {
  color: #fff !important;
}

#status_bar {
  margin: 0;
  border-width: 4px 0;
  border-color: transparent;
}

.navbar-inverse .divider-vertical {
  border-right-color: #222222;
  border-left-color: #111111;
}

@media (max-width: 767px) {
  #large-header {
    display: none;
  }
  .navbar-header {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  }
  .navbar-brand {
    display: block;
  }
  .navbar-collapse .nav>.divider-vertical {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-brand {
    display: none;
  }
}

rect.bordered {
  stroke: $background-color;
  stroke-width: 1px;
}

#posts {
  div div p img {
    max-width: 123px;
    float: left;
    margin-right: 5px;
  }
}

.elevator-button {
  text-align: center;
  margin: auto;
  cursor: pointer;
  color: $nav-link-color;
}
